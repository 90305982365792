import { Route, Routes, Navigate } from 'react-router-dom';
import Session from '../utils/Session';
import LoginPage from '../pages/Login/Login';
//import HomePage from './Home/Home';
import Formulario from './Formulario/Formulario';
import FormularioEdit from './Formulario/FormularioEdit';
import FazerInscricao from './Inscricao/FazerInscricao';
import InscricoesRealizadas from './Inscricao/InscricoesRealizadas';
import InscricaoConcluida from './Inscricao/InscricaoConcluida';

export default function Router() {
    return (
        <Routes>
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                }
            />
            <Route path="/inscricao/:id" element={<FazerInscricao />} />
            <Route path="/InscricaoConcluida/:id" element={<InscricaoConcluida />} />
            <Route
                path="/inscricoesRealizadas/:id"
                element={
                    <PrivateRoute>
                        <InscricoesRealizadas />
                    </PrivateRoute>
                }
            />
            {/*<Route
                path="/"
                element={
                    <PrivateRoute>
                        <HomePage />
                    </PrivateRoute>
                }
            />*/}
            <Route
                path="/cadastroFormulario"
                element={
                    <PrivateRoute>
                        <Formulario />
                    </PrivateRoute>
                }
            />
            <Route
                path="/cadastroFormulario/:id"
                element={
                    <PrivateRoute>
                        <FormularioEdit />
                    </PrivateRoute>
                }
            />
            <Route path="/*" element={<Navigate to="/cadastroFormulario" replace />} />
        </Routes>
    );
}

function PrivateRoute({ children }: { children: JSX.Element }) {
    return Session.isValid() ? children : <Navigate to="/login" replace />;
}

function PublicRoute({ children }: { children: JSX.Element }) {
    return !Session.isValid() ? children : <Navigate to="/" replace />;
}
