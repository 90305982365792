import axios from 'axios';
import Session from '../utils/Session';

export interface IInscricoesResponse {
    id: string;
    dataInscricao: Date;
    dataAtual: Date;
    campos: ICampoInscricao[];
}

export interface ICampoInscricao {
    id: string;
    resposta: any;
}

export interface IEnviarInscricao {
    formId: string;
    campos: ICampoEnviarInscricao[];
}

export interface ICampoEnviarInscricao {
    id: string;
    resposta: any;
    tipo: 'string' | 'email' | 'topic' | 'integer' | 'fone' | 'boolean' | 'date' | 'datetime-local';
}

async function obterInscricoes(idForm: string): Promise<IInscricoesResponse[]> {
    const query = {
        structuredQuery: {
            from: [
                {
                    collectionId: 'inscricoes',
                    allDescendants: true,
                },
            ],
            where: {
                compositeFilter: {
                    filters: [
                        {
                            fieldFilter: {
                                field: {
                                    fieldPath: 'formulario',
                                },
                                op: 'EQUAL',
                                value: {
                                    referenceValue: `projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios/${idForm}`,
                                },
                            },
                        },
                    ],
                    op: 'AND',
                },
            },
        },
    };
    const response = await axios.post(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents:runQuery`,
        query,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
    const dateRequest = new Date(response.headers['date'] ?? '');
    return parseInscricao(response.data, dateRequest);
}

async function salvarInscricao(payload: IEnviarInscricao) {
    const data = parseFormToFirebase(payload);
    await axios.post(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/inscricoes`,
        data
    );
}

async function deleteInscricao(id: string) {
    await axios.delete(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/inscricoes/${id}`,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
}

function parseInscricao(data: any, dateRequest: Date): IInscricoesResponse[] {
    if (!data) {
        return [];
    }
    let forms: IInscricoesResponse[] = [];
    for (let item of data) {
        if (!!item?.document) {
            forms.push(parseDataInscricao(item, dateRequest));
        }
    }
    return forms;
}

function parseDataInscricao(item: any, dateRequest: Date): IInscricoesResponse {
    const form: IInscricoesResponse = {
        id: extractId(item?.document?.name),
        dataInscricao: new Date(item?.document?.createTime),
        campos: parseCampos(item?.document?.fields),
        dataAtual: dateRequest,
    };
    return form;
}

function parseCampos(data: any): ICampoInscricao[] {
    if (!data) {
        return [];
    }
    const campos: ICampoInscricao[] = [];
    for (let key in data) {
        if (key !== 'formulario') {
            let resposta: any = '';
            for (let keyField in data[key]) {
                resposta = data[key][keyField];
            }
            const campo: ICampoInscricao = {
                id: key,
                resposta: resposta,
            };
            campos.push(campo);
        }
    }

    return campos;
}

function parseFormToFirebase(payload: IEnviarInscricao): any {
    const fields: any = {
        formulario: {
            referenceValue: `projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios/${payload.formId}`,
        },
    };
    for (let item of payload.campos) {
        if (item.tipo === 'string') {
            fields[item.id] = {
                stringValue: item.resposta,
            };
        } else if (item.tipo === 'email') {
            fields[item.id] = {
                stringValue: item.resposta,
            };
        } else if (item.tipo === 'integer') {
            fields[item.id] = {
                integerValue: item.resposta,
            };
        } else if (item.tipo === 'boolean') {
            fields[item.id] = {
                booleanValue: item.resposta ?? false,
            };
        } else if (item.tipo === 'fone') {
            fields[item.id] = {
                stringValue: item.resposta,
            };
        } else if (item.tipo === 'date') {
            fields[item.id] = {
                timestampValue: new Date(item.resposta),
            };
        } else if (item.tipo === 'datetime-local') {
            fields[item.id] = {
                timestampValue: new Date(item.resposta),
            };
        }
    }
    return { fields };
}

function extractId(id: string): string {
    return id.split('/')[6];
}

export default {
    obterInscricoes,
    deleteInscricao,
    salvarInscricao,
};
