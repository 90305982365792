import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';

import { IFormulariosCampo } from '../../services/FormulariosSvc';
import Success from '../../assets/lottie/success.json';

import calvaryLogo from '../../assets/images/logo_calvary.png';

export interface IFormNovoCampo extends Omit<IFormulariosCampo, 'tipo'> {
    tipo: IFormulariosCampo['tipo'] | undefined;
    novo?: boolean;
}

function InscricaoConcluida() {
    let { id } = useParams();
    let navigate = useNavigate();
    const idRef = useRef<string>(id ?? '');

    function irParaNovaInscricao() {
        navigate(`/inscricao/${idRef.current}`, {
            replace: true,
        });
    }

    return (
        <div
            className="box-page"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `linear-gradient(black, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9), black), url('${process.env.PUBLIC_URL}/images/calvary.jpg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '100vh',
            }}
        >
            <img src={calvaryLogo} height="80px" width="80px" style={{ marginRight: 10 }} alt="logo" />
            <h4 className="text-white">Calvary Campo</h4>

            <div
                className="box-page-content"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    className="flex-center"
                    style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#141414', padding: '30px', borderRadius: '15px' }}
                >
                    <Lottie
                        height={150}
                        width={150}
                        options={{
                            animationData: Success,
                            loop: false,
                        }}
                    />
                    <h4 className="text-white">Inscrição realizada com sucesso!</h4>
                    <br />
                    <button type="button" className="btn btn-primary" onClick={irParaNovaInscricao}>
                        Quero realizar uma nova inscrição
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InscricaoConcluida;
