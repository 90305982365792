function validate(url: string) {
    let validChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-.';
    url.split('').forEach((cr) => {
        let isValid = false;
        validChar.split('').forEach((crValid) => {
            if (cr === crValid) {
                isValid = true;
            }
        });
        if (!isValid) {
            throw new Error('URL informada contém caracteres não permitidos');
        }
    });
}

export default {
    validate,
};
