import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FiTrash2, FiEyeOff, FiEye } from 'react-icons/fi';
import { VscLinkExternal } from 'react-icons/vsc';

import { ShowToastError, ShowToastSucess } from '../../components/toast/Toast';
import Header from '../Home/Header';
import FormularioSvc, { IFormularioRequest, IFormulariosCampo, IFormulariosResponse } from '../../services/FormulariosSvc';
import DateFormat from '../../utils/DateFormat';
import Uuid from '../../utils/Uuid';
import Session from '../../utils/Session';
import ToastQuestion, { IToastQuestionParams } from '../../components/toast/ToastQuestion';
import UrlValidate from '../../utils/UrlValidate';

export interface IFormNovoCampo extends Omit<IFormulariosCampo, 'tipo'> {
    tipo: IFormulariosCampo['tipo'] | undefined;
    novo?: boolean;
}

const opcoesTipo = [
    {
        tipo: 'string',
        desc: 'Texto',
    },
    {
        tipo: 'topic',
        desc: 'Tópico',
    },
    {
        tipo: 'email',
        desc: 'E-mail',
    },
    {
        tipo: 'integer',
        desc: 'Número',
    },
    {
        tipo: 'fone',
        desc: 'Telefone',
    },
    {
        tipo: 'boolean',
        desc: 'Sim ou Não',
    },
    {
        tipo: 'date',
        desc: 'Data',
    },
    {
        tipo: 'datetime-local',
        desc: 'Data e Hora',
    },
];

function FormularioEdit() {
    let navigate = useNavigate();
    let { id } = useParams();
    const token = useRef(Session.getInfoToken());
    const idRef = useRef<string>(id ?? '');
    const isNovoRef = useRef(id === 'novo');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [form, setForm] = useState<IFormulariosResponse | null>(null);
    const [campos, setCampos] = useState<IFormNovoCampo[]>([]);
    const [url, setUrl] = useState('');
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [dataLimite, setDataLimite] = useState<Date | null>(null);
    const [ativo, setAtivo] = useState(true);
    const [oculto, setOculto] = useState(false);
    const [novoCampo, setNovoCampo] = useState<IFormNovoCampo | null>(null);
    const [question, setQuestion] = useState<IToastQuestionParams | null>(null);

    function verForm(id: string) {
        window.open(`/inscricao/${id}`, '_blank');
    }

    function verInscritos(id: string) {
        window.open(`/inscricoesRealizadas/${id}`, '_blank');
    }

    function verUrl() {
        if (url !== '') {
            window.open(`${process.env.REACT_APP_URL_SITE}/${url}`, '_blank');
        }
    }

    function addNovoCampo() {
        if (novoCampo) {
            return;
        }
        setNovoCampo({
            id: Uuid.v4(),
            nome: '',
            tipo: undefined,
            ativo: true,
            novo: true,
            obrigatorio: true,
        });
    }

    function saveNovoCampo() {
        if (novoCampo?.nome === '') {
            return ShowToastError('Preencha o nome do campo');
        }
        if (!novoCampo?.tipo) {
            return ShowToastError('Preencha o tipo do campo');
        }
        let newArr = [...campos];
        newArr.push(novoCampo);
        setCampos(newArr);
        setNovoCampo(null);
    }

    function confirmacaoExcluirCampo(campo: IFormNovoCampo) {
        setQuestion({
            title: 'Confirmação',
            description: `Deseja realmente excluir este campo?`,
            onOk: () => {
                setQuestion(null);
                excluirCampo(campo);
            },
            onClose: () => setQuestion(null),
        });
    }

    function excluirCampo(campo: IFormNovoCampo) {
        setCampos(campos.filter((it) => it.id !== campo.id));
    }

    function ocultarCampo(campo: IFormNovoCampo) {
        if (campo.novo) {
            setCampos(campos.filter((it) => it.id !== campo.id));
        } else {
            setCampos(
                campos.map((it) => {
                    if (it.id === campo.id) {
                        it.ativo = false;
                    }
                    return it;
                })
            );
        }
    }

    function exibirCampo(campo: IFormNovoCampo) {
        setCampos(
            campos.map((it) => {
                if (it.id === campo.id) {
                    it.ativo = true;
                }
                return it;
            })
        );
    }

    function moveArray(fromIndex: number, toIndex: number) {
        if (toIndex < 0 || toIndex > campos.length - 1) {
            return;
        }
        let cloneArr = [...campos];
        var element = cloneArr[fromIndex];
        cloneArr.splice(fromIndex, 1);
        cloneArr.splice(toIndex, 0, element);
        setCampos(cloneArr);
    }

    async function salvarFormulario() {
        try {
            if (url === '') {
                return ShowToastError('Preencha o campo de título');
            }

            UrlValidate.validate(url);

            if (titulo === '') {
                return ShowToastError('Preencha o campo de título');
            }

            if (dataLimite === null) {
                return ShowToastError('Preencha o campo data limite');
            }

            if (!campos.some((it) => it.ativo)) {
                return ShowToastError('É necessário ter pelo menos um campo!');
            }

            if (novoCampo) {
                return ShowToastError('Ainda há campos em edição!');
            }

            setSaving(true);
            const data: IFormularioRequest = {
                url,
                titulo,
                descricao,
                dataLimite: dataLimite as Date,
                ativo,
                oculto,
                criadoPor: token.current?.email ?? '',
                campos: campos.map((it) => ({
                    id: it.id,
                    nome: it.nome,
                    tipo: it.tipo as IFormulariosCampo['tipo'],
                    obrigatorio: it.obrigatorio,
                    ativo: it.ativo,
                })),
            };

            if (isNovoRef.current) {
                const response = await FormularioSvc.salvarFormulario(data);
                isNovoRef.current = false;
                idRef.current = response.id;
                navigate(`/cadastroFormulario/${response.id}`, {
                    replace: true,
                    state: null,
                });
            } else {
                const response = await FormularioSvc.alterarFormulario(idRef.current, data);
                isNovoRef.current = false;
                idRef.current = response.id;
                navigate(`/cadastroFormulario/${response.id}`, {
                    replace: true,
                    state: null,
                });
            }
            obterFormulario();
            setSaving(false);
            ShowToastSucess('Formulário salvo com sucesso!');
        } catch (error) {
            setSaving(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }

    const obterFormulario = useCallback(async () => {
        try {
            if (isNovoRef.current) {
                return;
            }
            setLoading(true);
            const response = await FormularioSvc.obterFormulario(idRef.current);
            setForm(response);
            setUrl(response.url);
            setTitulo(response.titulo);
            setDescricao(response.descricao);
            setDataLimite(response.dataLimite);
            setAtivo(response.ativo ?? false);
            setOculto(response.oculto ?? false);
            setCampos(response.campos);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }, []);

    useEffect(() => {
        obterFormulario();
    }, [obterFormulario]);

    return (
        <>
            <Header />
            <div className="box-page" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div className="box-page-header">
                    <h2 className="text-page-title text-white">
                        {isNovoRef.current ? 'Novo formulário' : `Editar formulário: ${idRef.current}`}
                    </h2>
                </div>
                <div
                    className="box-page-content"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                    {saving && (
                        <div className="flex-center">
                            <div className="spinner-border text-light" role="status" style={{ marginRight: 10 }} />
                        </div>
                    )}
                    {loading ? (
                        <div className="flex-center">
                            <div className="spinner-border text-light" role="status" style={{ marginRight: 10 }} />
                        </div>
                    ) : (
                        <Form
                            className="col-sm-6"
                            onSubmit={(e) => {
                                e.preventDefault();
                                salvarFormulario();
                            }}
                        >
                            {!!form && (
                                <>
                                    <h5 className="text-white">Criado por: {form.criadoPor}</h5>
                                    <h5 className="text-white">Criado em: {DateFormat.formatarDataHora(form.criadoEm)}</h5>
                                    <br />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => verForm(idRef.current)}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Testar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm"
                                            onClick={() => verInscritos(idRef.current)}
                                        >
                                            Ver Inscritos
                                        </button>
                                    </div>
                                    <br />
                                </>
                            )}
                            <Form.Group className="mb-3" controlId="nome">
                                <Form.Label>*URL</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>/</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Exemplo: conexaocalvary"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value.trim())}
                                    />
                                    <Button variant="outline-secondary" id="button-addon2" onClick={verUrl}>
                                        <VscLinkExternal />
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="nome">
                                <Form.Label>*Nome</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Informe o nome"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Informe a descrição"
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>*Prazo para inscrição</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    placeholder="Informe o prazo para inscrições"
                                    value={DateFormat.parseToInputDateTime(dataLimite)}
                                    onChange={(e) => setDataLimite(!!e.target.value ? new Date(e.target.value) : null)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="ativo">
                                <Form.Check
                                    type="checkbox"
                                    label="Ocultar na página inicial?"
                                    checked={oculto}
                                    onChange={(e) => setOculto(e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="ativo">
                                <Form.Check type="checkbox" label="Ativo?" checked={ativo} onChange={(e) => setAtivo(e.target.checked)} />
                            </Form.Group>

                            <Table
                                striped
                                bordered
                                hover
                                variant="dark"
                                style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'block', width: '100%' }}
                            >
                                <tbody style={{ display: 'table', width: '100%' }}>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Tipo</th>
                                        <th>Obrigatório?</th>
                                        <th>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <button type="button" className="btn btn-primary btn-sm" onClick={addNovoCampo}>
                                                    +
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                    {campos.map((item, index) => (
                                        <tr key={item?.id}>
                                            <td style={{ textDecoration: item.ativo ? 'none' : 'line-through' }}>{item?.nome ?? ''}</td>
                                            <td>{opcoesTipo.find((it) => it.tipo === item?.tipo)?.desc ?? ''}</td>
                                            <td>{item?.obrigatorio ? 'Sim' : 'Não'}</td>
                                            <td>
                                                <div className="btn-table-edit">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => moveArray(index, index - 1)}
                                                    >
                                                        ▴
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => moveArray(index, index + 1)}
                                                    >
                                                        ▾
                                                    </button>
                                                    {item.ativo ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => ocultarCampo(item)}
                                                        >
                                                            <FiEyeOff />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => exibirCampo(item)}
                                                        >
                                                            <FiEye />
                                                        </button>
                                                    )}
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => confirmacaoExcluirCampo(item)}
                                                    >
                                                        <FiTrash2 />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {!!novoCampo && (
                                        <tr>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Nome do campo"
                                                    value={novoCampo['nome']}
                                                    onChange={(e) =>
                                                        setNovoCampo({
                                                            ...novoCampo,
                                                            nome: e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    value={novoCampo['tipo']}
                                                    onChange={(e) =>
                                                        setNovoCampo({
                                                            ...novoCampo,
                                                            tipo: e.target.value as any,
                                                        })
                                                    }
                                                >
                                                    <option value={''}>Tipo de campo</option>
                                                    {opcoesTipo.map((item) => (
                                                        <option key={item.tipo} value={item.tipo}>
                                                            {item.desc}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={novoCampo['obrigatorio']}
                                                    onChange={(e) =>
                                                        setNovoCampo({
                                                            ...novoCampo,
                                                            obrigatorio: e.target.checked,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <div className="btn-table-edit">
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={saveNovoCampo}>
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => setNovoCampo(null)}
                                                    >
                                                        Remover
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <br />
                            <Button variant="primary" type="submit">
                                Salvar
                            </Button>
                        </Form>
                    )}
                </div>
                <br />
                <br />
                <br />
            </div>
            {!!question && <ToastQuestion info={question} />}
        </>
    );
}

export default FormularioEdit;
