import axios from 'axios';
import Session from '../utils/Session';

export interface IFormulariosResponse {
    id: string;
    url: string;
    titulo: string;
    descricao: string;
    dataLimite: Date;
    ativo: boolean;
    oculto: boolean;
    criadoEm: Date;
    criadoPor: string;
    dataAtual: Date;
    campos: IFormulariosCampo[];
}

export interface IFormularioRequest {
    url: string;
    titulo: string;
    descricao: string;
    dataLimite: Date;
    ativo: boolean;
    oculto: boolean;
    criadoPor: string;
    campos: IFormulariosCampo[];
}

export interface IFormulariosCampo {
    id: string;
    nome: string;
    tipo: 'string' | 'topic' | 'email' | 'integer' | 'fone' | 'boolean' | 'date' | 'datetime-local';
    obrigatorio: boolean;
    ativo: boolean;
}

async function obterFormularios(): Promise<IFormulariosResponse[]> {
    const response = await axios.get(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios`,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
    const dateRequest = new Date(response.headers['date'] ?? '');
    return parseDataFormularios(response.data, dateRequest);
}

async function obterFormulario(id: string): Promise<IFormulariosResponse> {
    const response = await axios.get(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios/${id}`,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
    const dateRequest = new Date(response.headers['date'] ?? '');
    return parseDataFormulario(response.data, dateRequest);
}

async function obterFormularioNoAuth(id: string): Promise<IFormulariosResponse> {
    const response = await axios.get(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios/${id}`
    );
    const dateRequest = new Date(response.headers['date'] ?? '');
    return parseDataFormulario(response.data, dateRequest);
}

async function salvarFormulario(payload: IFormularioRequest): Promise<IFormulariosResponse> {
    const data = parseFormToFirebase(payload);
    const response = await axios.post(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios`,
        data,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
    const dateRequest = new Date(response.headers['date'] ?? '');
    return parseDataFormulario(response.data, dateRequest);
}

async function alterarFormulario(id: string, payload: IFormularioRequest): Promise<IFormulariosResponse> {
    const data = parseFormToFirebase(payload);
    const response = await axios.patch(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios/${id}`,
        data,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
    const dateRequest = new Date(response.headers['date'] ?? '');
    return parseDataFormulario(response.data, dateRequest);
}

async function deleteFormulario(id: string) {
    await axios.delete(
        `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/formularios/${id}`,
        {
            headers: {
                Authorization: `Bearer ${Session.get()?.idToken ?? ''}`,
            },
        }
    );
}

function parseDataFormularios(data: any, dateRequest: Date): IFormulariosResponse[] {
    if (!data?.documents) {
        return [];
    }
    let forms: IFormulariosResponse[] = [];
    for (let item of data?.documents) {
        forms.push(parseDataFormulario(item, dateRequest));
    }
    return forms;
}

function parseDataFormulario(item: any, dateRequest: Date): IFormulariosResponse {
    const form: IFormulariosResponse = {
        id: extractId(item?.name),
        titulo: item?.fields?.titulo?.stringValue,
        url: item?.fields?.url?.stringValue,
        descricao: item?.fields?.descricao?.stringValue,
        dataLimite: new Date(item?.fields?.dataLimite?.timestampValue ?? new Date()),
        ativo: item?.fields?.ativo?.booleanValue,
        oculto: item?.fields?.oculto?.booleanValue,
        criadoEm: new Date(item?.createTime ?? new Date()),
        criadoPor: item?.fields?.criadoPor?.stringValue,
        campos: parseCampos(item?.fields?.campos?.arrayValue),
        dataAtual: dateRequest,
    };
    return form;
}

function parseCampos(data: any): IFormulariosCampo[] {
    if (!data?.values) {
        return [];
    }
    const campos: IFormulariosCampo[] = [];
    for (let item of data?.values) {
        const campo: IFormulariosCampo = {
            id: item?.mapValue?.fields?.id?.stringValue,
            nome: item?.mapValue?.fields?.nome?.stringValue,
            tipo: item?.mapValue?.fields?.tipo?.stringValue,
            obrigatorio: item?.mapValue?.fields?.obrigatorio?.booleanValue,
            ativo: item?.mapValue?.fields?.ativo?.booleanValue,
        };
        campos.push(campo);
    }

    return campos;
}

function parseFormToFirebase(form: IFormularioRequest): any {
    const data: any = {
        fields: {
            url: {
                stringValue: form.url,
            },
            titulo: {
                stringValue: form.titulo,
            },
            descricao: {
                stringValue: form.descricao,
            },
            dataLimite: {
                timestampValue: form.dataLimite.toISOString(),
            },
            oculto: {
                booleanValue: form.oculto,
            },
            ativo: {
                booleanValue: form.ativo,
            },
            criadoPor: {
                stringValue: form.criadoPor,
            },
            campos: {
                arrayValue: {
                    values: form.campos.map((item) => ({
                        mapValue: {
                            fields: {
                                id: {
                                    stringValue: item.id,
                                },
                                nome: {
                                    stringValue: item.nome,
                                },
                                tipo: {
                                    stringValue: item.tipo,
                                },
                                obrigatorio: {
                                    booleanValue: item.obrigatorio,
                                },
                                ativo: {
                                    booleanValue: item.ativo,
                                },
                            },
                        },
                    })),
                },
            },
        },
    };
    return data;
}

function extractId(id: string): string {
    return id.split('/')[6];
}

export default {
    obterFormularios,
    obterFormulario,
    obterFormularioNoAuth,
    salvarFormulario,
    alterarFormulario,
    deleteFormulario,
};
