import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function parseToInputDateTime(date: Date | null): string {
    if (!date) {
        return '';
    }

    const ano = new Date(date).getFullYear();
    const mes = String(new Date(date).getMonth() + 1).padStart(2, '0');
    const dia = String(new Date(date).getDate()).padStart(2, '0');
    const hora = String(new Date(date).getHours()).padStart(2, '0');
    const minuto = String(new Date(date).getMinutes()).padStart(2, '0');

    return `${ano}-${mes}-${dia}T${hora}:${minuto}`;
}

function formatDate(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), new Date(data).getFullYear() === new Date().getFullYear() ? 'dd/MMM' : 'dd/MMM/yy', { locale: ptBR });
}

function formatWithCropDate(data?: Date | null): string {
    if (!data) {
        return '';
    }
    const dataStr = new Date(data).toISOString();
    return `${dataStr.substring(8, 10)}/${dataStr.substring(5, 7)}/${dataStr.substring(0, 4)}`;
}

function formatarDataCompleta(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), 'dd/MMM/yyyy', { locale: ptBR });
}

function formatarDataHora(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), new Date(data).getFullYear() === new Date().getFullYear() ? 'dd/MMM HH:mm' : 'dd/MMM/yy HH:mm', {
        locale: ptBR,
    });
}

function formatarDataHoraCompleta(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
}

function formatarDataDiaSemana(data?: Date | null): string {
    if (!data) {
        return '';
    }

    const dataFormat = format(new Date(data), new Date(data).getFullYear() === new Date().getFullYear() ? 'dd/MMM' : 'dd/MMM/yy', {
        locale: ptBR,
    });

    return `${diaSemana[new Date(data).getDay()]}, ${dataFormat}`;
}

function obterDiaSemana(data?: Date | null) {
    if (!data) {
        return '';
    }
    return diaSemana[new Date(data).getDay()];
}

const diaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

function formatarDataISO(data?: Date | string | null): string | null {
    if (!data) {
        return null;
    }

    const ano = new Date(data).getFullYear();
    const mes = String(new Date(data).getMonth() + 1).padStart(2, '0');
    const dia = String(new Date(data).getDate()).padStart(2, '0');

    return `${ano}-${mes}-${dia}`;
}

function formatarDataNomeArquivo(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), 'yyyyMMddhhmmss', { locale: ptBR });
}

function obterMes(mes: string | number) {
    switch (parseInt(String(mes), 10)) {
        case 1:
            return 'Janeiro';
        case 2:
            return 'Fevereiro';
        case 3:
            return 'Março';
        case 4:
            return 'Abril';
        case 5:
            return 'Maio';
        case 6:
            return 'Junho';
        case 7:
            return 'Julho';
        case 8:
            return 'Agosto';
        case 9:
            return 'Setembro';
        case 10:
            return 'Outubro';
        case 11:
            return 'Novembro';
        case 12:
            return 'Dezembro';
    }
}

export default {
    formatWithCropDate,
    parseToInputDateTime,
    formatDate,
    formatarDataCompleta,
    formatarDataHora,
    formatarDataDiaSemana,
    obterDiaSemana,
    obterMes,
    formatarDataISO,
    formatarDataNomeArquivo,
    formatarDataHoraCompleta,
};
