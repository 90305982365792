import { GiCardboardBox } from 'react-icons/gi';

function EmptyList({ text }: { text: string }) {
    return (
        <div className="box-empty-list">
            <GiCardboardBox className="ico-empty-list" />
            <span className="text-empty-list">{text}</span>
        </div>
    );
}

export default EmptyList;
