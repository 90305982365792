import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import SocketEvent from 'react-socket-event';

interface ToastParams {
    title: string;
    description: string;
    variant: 'danger' | 'success' | 'warning' | 'dark';
    textColor: 'white' | 'black';
}

export function ShowToastSucess(description: string) {
    const info: ToastParams = {
        title: 'Sucesso!',
        description,
        variant: 'success',
        textColor: 'white',
    };
    SocketEvent.emit('TOAST_GLOBAL', info);
}

export function ShowToastError(description: string) {
    const info: ToastParams = {
        title: 'Ops, ocorreu um erro',
        description,
        variant: 'danger',
        textColor: 'white',
    };
    SocketEvent.emit('TOAST_GLOBAL', info);
}

export default function ToastComponent() {
    const [info, setInfo] = useState<ToastParams | null>(null);

    useEffect(() => {
        SocketEvent.on(
            'TOAST_GLOBAL',
            (params: ToastParams) => {
                setInfo(params);
            },
            'TOAST_GLOBAL'
        );
    }, []);

    return (
        <div style={{ width: '100%', position: 'fixed', top: 0, right: 0 }}>
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setInfo(null)} autohide show={!!info} delay={4000} bg={info?.variant}>
                    {!!info?.title && (
                        <Toast.Header>
                            <strong className="mr-auto">{info?.title}</strong>
                        </Toast.Header>
                    )}
                    <Toast.Body className="text-white">
                        <span style={{ color: info?.textColor }}>{info?.description}</span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}
