import { FormEvent, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

import calvaryLogo from '../../assets/images/logo_calvary.png';
import Session from '../../utils/Session';
import { ShowToastError } from '../../components/toast/Toast';

function Login() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    async function submit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            const target = e.target as typeof e.target & {
                email: { value: string };
                password: { value: string };
            };
            const email = target.email.value;
            const password = target.password.value;

            if (email === '') {
                return ShowToastError('Preenche o campo e-mail');
            }
            if (password === '') {
                return ShowToastError('Preenche o campo senha');
            }

            setLoading(true);

            const response = await axios.post(
                `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
                {
                    email,
                    password,
                    returnSecureToken: true,
                }
            );

            Session.set(response.data);
            navigate('/', {
                replace: true,
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }

    return (
        <div className="loginBox">
            <img className="user" src={calvaryLogo} height="80px" width="80px" alt="logo" />
            <h4>Login (Uso restrito)</h4>
            <form id="form-login" onSubmit={submit}>
                <div className="inputBox">
                    <input id="email" type="email" name="email" placeholder="E-mail" />
                    <input id="password" type="password" name="password" placeholder="Senha" />
                </div>
                <button type="submit">
                    {loading && (
                        <div className="spinner-border text-light spinner-border-sm" role="status" style={{ marginRight: 10 }}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                    Login
                </button>
            </form>
        </div>
    );
}

export default Login;
