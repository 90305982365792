interface IIdToken {
    aud: string;
    email: string;
    exp: number;
    iat: number;
    iss: string;
    sign_in_provider: string;
    user_id: string;
    verified: boolean;
}

interface ISession {
    kind: string;
    localId: string;
    email: string;
    displayName: string;
    idToken: string;
    registered: true;
    refreshToken: string;
    expiresIn: string;
}

function set(data: ISession) {
    window.localStorage.setItem('@SESSION', JSON.stringify(data));
}

function get(): ISession | null {
    const data = window.localStorage.getItem('@SESSION');
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

function clear() {
    window.localStorage.removeItem('@SESSION');
}

function getInfoToken(): IIdToken | null {
    const info = get();
    if (info) {
        const token = info.idToken ?? '';
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
    return null;
}

function isValid(): boolean {
    const info = get();
    if (info) {
        const token = info.idToken ?? '';
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        const { exp } = JSON.parse(jsonPayload);

        return exp * 1000 > new Date().getTime();
    }
    return false;
}

export default {
    set,
    get,
    clear,
    getInfoToken,
    isValid,
};
