import { ReactNode } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export interface IToastQuestionParams {
    title: string;
    description: string | ReactNode;
    onOk?: () => void;
    onClose: () => void;
    options?: Array<{
        title: string;
        onPress: () => void;
    }>;
}

export default function ToastQuestion({ info }: { info: IToastQuestionParams }) {
    return (
        <div className="no-print" style={{ width: '100%', height: '100%', position: 'fixed', top: 0, right: 0 }}>
            <ToastContainer className="p-3" position="middle-center">
                <Toast onClose={info.onClose} show={!!info} bg="dark">
                    {!!info?.title && (
                        <Toast.Header>
                            <strong className="mr-auto">{info?.title}</strong>
                        </Toast.Header>
                    )}
                    <Toast.Body className="text-white">
                        <span style={{ color: 'white' }}>{info?.description}</span>

                        {!!info.options ? (
                            <div className="mt-2 pt-2 border-top">
                                {info.options.map((it) => (
                                    <button
                                        key={it.title}
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={it.onPress}
                                        style={{ marginRight: '20px' }}
                                    >
                                        {it.title}
                                    </button>
                                ))}
                            </div>
                        ) : (
                            <div className="mt-2 pt-2 border-top">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={info.onOk}
                                    style={{ marginRight: '20px' }}
                                >
                                    Ok
                                </button>
                                <button type="button" className="btn btn-secondary btn-sm" onClick={info.onClose}>
                                    cancelar
                                </button>
                            </div>
                        )}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}
