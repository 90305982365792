import { useCallback, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { HiArrowsUpDown } from 'react-icons/hi2';

import { ShowToastError } from '../../components/toast/Toast';
import Header from '../Home/Header';
import FormularioSvc, { IFormulariosCampo, IFormulariosResponse } from '../../services/FormulariosSvc';
import DateFormat from '../../utils/DateFormat';
import ToastQuestion, { IToastQuestionParams } from '../../components/toast/ToastQuestion';
import InscricoesSvc, { ICampoInscricao, IInscricoesResponse } from '../../services/InscricoesSvc';
import EmptyList from '../../components/indicator/EmptyList';

function InscricoesRealizadas() {
    let { id } = useParams();
    const idRef = useRef<string>(id ?? '');
    const [loading, setLoading] = useState(true);
    const [inscricoes, setInscricoes] = useState<IInscricoesResponse[]>([]);
    const [question, setQuestion] = useState<IToastQuestionParams | null>(null);
    const [form, setForm] = useState<IFormulariosResponse | null>(null);
    const [orderColumns, setOrderColumns] = useState<any>({});

    function confirmacaoImprimir() {
        setQuestion({
            title: 'Impressão',
            description: (
                <span>
                    Em qual orientação deseja imprimir?
                    <br />
                    <br />
                    Dica: se houver muitas colunas imprima em modo paisagem.
                </span>
            ),
            options: [
                {
                    title: 'Retrato',
                    onPress: () => {
                        setQuestion(null);
                        imprimir('portrait');
                    },
                },
                {
                    title: 'Paisagem',
                    onPress: () => {
                        setQuestion(null);
                        imprimir('landscape');
                    },
                },
            ],
            onClose: () => setQuestion(null),
        });
    }

    function imprimir(orientacao: 'portrait' | 'landscape') {
        var css = `@page { size: A4 ${orientacao}; }`,
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style') as any;

        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
    }

    function sortColummn(field: IFormulariosCampo) {
        const orderAsc = orderColumns[field.id] ?? true;
        setInscricoes(sortArray(inscricoes, field, orderAsc));
        setOrderColumns({ ...orderColumns, [field.id]: !orderAsc });
    }

    function orderDataInscricao() {
        const orderAsc = orderColumns['dataInscricao'] ?? true;
        setInscricoes(
            inscricoes.sort((a, b) => {
                const val = new Date(a.dataInscricao).getTime() - new Date(b.dataInscricao).getTime();
                return orderAsc ? val : val * -1;
            })
        );
        setOrderColumns({ ...orderColumns, dataInscricao: !orderAsc });
    }

    function exportCSV() {
        let csv = '';
        let line = '';

        line = `${line}"Ordem";`;
        line = `${line}"Data Inscrição";`;
        for (let header of form?.campos ?? []) {
            line = `${line}"${header.nome}";`;
        }
        csv = `${line}\n`;

        inscricoes.forEach((value, index) => {
            line = '';
            line = `${line}"${index + 1}";`;
            line = `${line}"${DateFormat.formatarDataHoraCompleta(value?.dataInscricao ?? null)}";`;
            for (let header of form?.campos ?? []) {
                const val = formatValueField(header, value.campos);
                line = `${line}"${val}";`;
            }
            csv = `${csv}${line}\n`;
        });

        let hiddenElement = document.createElement('a');
        let universalBOM = '\uFEFF';
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(`${universalBOM}${csv}`);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${form?.titulo.toLocaleLowerCase().replaceAll(' ', '_')}.csv`;
        hiddenElement.click();
    }

    async function deleteForm(item: IInscricoesResponse) {
        try {
            await InscricoesSvc.deleteInscricao(item.id);
            obterInscricoes();
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }

    function confirmacaoDelete(item: IInscricoesResponse) {
        setQuestion({
            title: 'Confirmação',
            description: `Deseja realmente excluir esta inscrição?`,
            onOk: () => {
                setQuestion(null);
                deleteForm(item);
            },
            onClose: () => setQuestion(null),
        });
    }

    const obterInscricoes = useCallback(async () => {
        try {
            setLoading(true);
            let responseForm = await FormularioSvc.obterFormulario(idRef.current);
            responseForm.campos = responseForm.campos.filter((it) => it.tipo !== 'topic');
            setForm(responseForm);

            const response = await InscricoesSvc.obterInscricoes(idRef.current);
            setInscricoes(
                response.sort((a, b) => {
                    return new Date(a.dataInscricao).getTime() - new Date(b.dataInscricao).getTime();
                })
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }, []);

    useEffect(() => {
        obterInscricoes();
    }, [obterInscricoes]);

    return (
        <>
            <Header />
            <div className="box-page">
                <div className="box-page-header">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h2 style={{ color: 'white' }} className="text-page-title">
                            Inscrições: {form?.titulo ?? ''}
                        </h2>
                        {!!form?.dataLimite && form.dataLimite.getTime() > new Date().getTime() ? (
                            <span
                                style={{
                                    marginLeft: '10px',
                                }}
                                className="no-print badge text-bg-success"
                            >
                                Em aberto
                            </span>
                        ) : (
                            <span
                                style={{
                                    marginLeft: '10px',
                                }}
                                className="no-print badge text-bg-primary"
                            >
                                Encerrado
                            </span>
                        )}
                        <button
                            type="button"
                            className="no-print btn btn-primary btn-sm"
                            onClick={obterInscricoes}
                            style={{ marginLeft: '15px' }}
                        >
                            Consultar
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="no-print btn btn-primary btn-sm"
                            onClick={confirmacaoImprimir}
                            style={{ marginLeft: '15px' }}
                        >
                            Imprimir
                        </button>
                        <button
                            type="button"
                            className="no-print btn btn-success btn-sm"
                            onClick={exportCSV}
                            style={{ marginLeft: '15px' }}
                        >
                            Exportar CSV
                        </button>
                    </div>
                </div>
                <div>
                    <p id="text-total-inscricao" style={{ color: 'white' }} className="text-end px-4">
                        Total de Inscrições: {inscricoes.length}
                    </p>
                </div>
                <div className="box-page-content">
                    {loading ? (
                        <div className="flex-center">
                            <div className="spinner-border text-light" role="status" style={{ marginRight: 10 }} />
                        </div>
                    ) : (
                        <Table
                            striped={true}
                            bordered
                            hover
                            variant="dark"
                            style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'block', width: '100%' }}
                        >
                            <tbody style={{ display: 'table', width: '100%' }}>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <button type="button" className="btn btn-dark w-100" onClick={orderDataInscricao}>
                                            Data Inscrição <HiArrowsUpDown className="no-print" />
                                        </button>
                                    </th>
                                    {form?.campos.map((it) => (
                                        <th
                                            key={it.id}
                                            style={{
                                                textDecoration: it.ativo ? 'none' : 'line-through',
                                            }}
                                        >
                                            <button type="button" className="btn btn-dark w-100" onClick={() => sortColummn(it)}>
                                                {it.nome} <HiArrowsUpDown className="no-print" />
                                            </button>
                                        </th>
                                    ))}
                                    <th className="no-print"></th>
                                </tr>
                                {inscricoes.map((item, index) => (
                                    <tr key={item.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{DateFormat.formatarDataHoraCompleta(item?.dataInscricao ?? null)}</td>
                                        {form?.campos.map((it) => (
                                            <td key={it.id} className="text-center">
                                                {formatValueField(it, item?.campos)}
                                            </td>
                                        ))}
                                        <td className="no-print">
                                            <div className="btn-table-edit">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => confirmacaoDelete(item)}
                                                >
                                                    Excluir
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {inscricoes.length === 0 && <EmptyList text="Nenhuma inscrição até o momento!" />}
                </div>
            </div>
            {!!question && <ToastQuestion info={question} />}
        </>
    );
}

function formatValueField(campo: IFormulariosCampo, camposInscricao: ICampoInscricao[]): string {
    const resposta = camposInscricao?.find((c) => c.id === campo.id)?.resposta ?? null;
    if (resposta === null) {
        return '';
    }
    if (campo.tipo === 'date') {
        return DateFormat.formatWithCropDate(resposta);
    } else if (campo.tipo === 'datetime-local') {
        return DateFormat.formatarDataHoraCompleta(resposta);
    } else if (campo.tipo === 'boolean') {
        return resposta ? 'Sim' : 'Não';
    } else {
        return resposta as string;
    }
}

function sortArray(array: IInscricoesResponse[], field: IFormulariosCampo, orderAsc: boolean): IInscricoesResponse[] {
    const newArr: IInscricoesResponse[] = JSON.parse(JSON.stringify(array));
    if (field.tipo === 'date' || field.tipo === 'datetime-local') {
        return newArr.sort((a, b) => {
            const valueA = new Date(a.campos.find((it) => it.id === field.id)?.resposta ?? -8640000000000000).getTime();
            const valueB = new Date(b.campos.find((it) => it.id === field.id)?.resposta ?? -8640000000000000).getTime();
            const val = valueA > valueB ? -1 : 1;
            return orderAsc ? val : val * -1;
        });
    } else if (field.tipo === 'boolean') {
        return newArr.sort((a, b) => {
            let valueA: string = a.campos.find((it) => it.id === field.id)?.resposta ?? 'a';
            let valueB: string = b.campos.find((it) => it.id === field.id)?.resposta ?? 'a';
            if (valueA !== 'a') {
                valueA = valueA ? 'b' : 'c';
            }
            if (valueB !== 'a') {
                valueB = valueB ? 'b' : 'c';
            }
            if (valueA === valueB) {
                return 0;
            }
            const val = valueA < valueB ? -1 : 1;
            return orderAsc ? val : val * -1;
        });
    } else if (field.tipo === 'integer') {
        return newArr.sort((a, b) => {
            let valueAStr: string = a.campos.find((it) => it.id === field.id)?.resposta ?? null;
            let valueBStr: string = b.campos.find((it) => it.id === field.id)?.resposta ?? null;
            const valueA = valueAStr === null ? Number.MIN_VALUE : parseFloat(valueAStr as string);
            const valueB = valueBStr === null ? Number.MIN_VALUE : parseFloat(valueBStr as string);
            const val = valueA < valueB ? -1 : 1;
            return orderAsc ? val : val * -1;
        });
    } else {
        return newArr.sort((a, b) => {
            const valueA = a.campos.find((it) => it.id === field.id)?.resposta ?? null;
            const valueB = b.campos.find((it) => it.id === field.id)?.resposta ?? null;
            const val = valueA < valueB ? -1 : 1;
            return orderAsc ? val : val * -1;
        });
    }
}

export default InscricoesRealizadas;
