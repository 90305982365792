import { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ShowToastError } from '../../components/toast/Toast';
import Header from '../Home/Header';

import FormularioSvc, { IFormulariosResponse } from '../../services/FormulariosSvc';
import DateFormat from '../../utils/DateFormat';
import ToastQuestion, { IToastQuestionParams } from '../../components/toast/ToastQuestion';

function Formulario() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [forms, setForms] = useState<IFormulariosResponse[]>([]);
    const [question, setQuestion] = useState<IToastQuestionParams | null>(null);

    function irParaForm(id: string) {
        navigate(`/cadastroFormulario/${id}`);
    }

    function verForm(id: string) {
        window.open(`/inscricao/${id}`, '_blank');
    }

    function verInscritos(id: string) {
        navigate(`/inscricoesRealizadas/${id}`);
    }

    function verUrl(url: string) {
        if (url !== '') {
            window.open(`${process.env.REACT_APP_URL_SITE}/${url}`, '_blank');
        }
    }

    async function deleteForm(item: IFormulariosResponse) {
        try {
            await FormularioSvc.deleteFormulario(item.id);
            obterFormularios();
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }

    function confirmacaoDelete(item: IFormulariosResponse) {
        setQuestion({
            title: 'Confirmação',
            description: `Deseja realmente excluir este formulário? (${item.titulo})`,
            onOk: () => {
                setQuestion(null);
                deleteForm(item);
            },
            onClose: () => setQuestion(null),
        });
    }

    const obterFormularios = useCallback(async () => {
        try {
            setLoading(true);
            const response = await FormularioSvc.obterFormularios();
            setForms(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }, []);

    useEffect(() => {
        obterFormularios();
    }, [obterFormularios]);

    return (
        <>
            <Header />
            <div className="box-page">
                <div className="box-page-header">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h2 className="text-page-title text-white">Cadastro Formulários</h2>
                        <button type="button" className="btn btn-primary btn-sm" onClick={obterFormularios} style={{ marginLeft: '15px' }}>
                            Consultar
                        </button>
                    </div>
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => irParaForm('novo')}>
                        Novo formulário
                    </button>
                </div>
                <div className="box-page-content">
                    {loading ? (
                        <div className="flex-center">
                            <div className="spinner-border text-light" role="status" style={{ marginRight: 10 }} />
                        </div>
                    ) : (
                        <Table
                            striped
                            bordered
                            hover
                            variant="dark"
                            style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'block', width: '100%' }}
                        >
                            <tbody style={{ display: 'table', width: '100%' }}>
                                <tr>
                                    <th>URL</th>
                                    <th>Título</th>
                                    <th>Data Cadastro</th>
                                    <th>Prazo</th>
                                    <th>Situação</th>
                                    <th></th>
                                </tr>
                                {forms.map((item) => (
                                    <tr key={item.id}>
                                        <td>
                                            <Button variant="link" onClick={() => verUrl(item?.url ?? '')}>
                                                /{item?.url ?? ''}
                                            </Button>
                                        </td>
                                        <td>{item?.titulo ?? ''}</td>
                                        <td>{DateFormat.formatarDataHora(item?.criadoEm ?? null)}</td>
                                        <td>
                                            {DateFormat.formatarDataHora(item?.dataLimite ?? null)}
                                            {!!item?.dataLimite && item.dataLimite.getTime() > new Date().getTime() ? (
                                                <span
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                    className="badge text-bg-success"
                                                >
                                                    Em aberto
                                                </span>
                                            ) : (
                                                <span
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                    className="badge text-bg-primary"
                                                >
                                                    Encerrado
                                                </span>
                                            )}
                                        </td>
                                        <td>{item?.ativo ? 'Ativo' : 'Inativo'}</td>
                                        <td>
                                            <div className="btn-table-edit">
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => verForm(item?.id)}>
                                                    Testar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => verInscritos(item?.id)}
                                                >
                                                    Ver Inscritos
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm"
                                                    onClick={() => irParaForm(item?.id)}
                                                >
                                                    Editar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => confirmacaoDelete(item)}
                                                >
                                                    Excluir
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
            {!!question && <ToastQuestion info={question} />}
        </>
    );
}

export default Formulario;
