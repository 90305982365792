import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import { useNavigate, useParams } from 'react-router-dom';

import { ShowToastError } from '../../components/toast/Toast';
import FormularioSvc, { IFormulariosCampo, IFormulariosResponse } from '../../services/FormulariosSvc';
import InfoProblema from '../../components/indicator/InfoProblema';

import calvaryLogo from '../../assets/images/logo_calvary.png';
import InscricoesSvc, { ICampoEnviarInscricao, IEnviarInscricao } from '../../services/InscricoesSvc';

export interface IFormNovoCampo extends Omit<IFormulariosCampo, 'tipo'> {
    tipo: IFormulariosCampo['tipo'] | undefined;
    novo?: boolean;
}

function FazerInscricao() {
    let { id } = useParams();
    let navigate = useNavigate();
    const idRef = useRef<string>(id ?? '');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [form, setForm] = useState<IFormulariosResponse | null>(null);
    const [campos, setCampos] = useState<IFormNovoCampo[]>([]);
    const [message, setMessage] = useState('');

    async function salvarInscricao(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            setSaving(true);

            const target: any = e.target;
            const fields: ICampoEnviarInscricao[] = [];
            for (let item of campos) {
                if (item.tipo !== 'topic') {
                    let resposta = item.tipo === 'boolean' ? target[item.id].checked : target[item.id].value;
                    if (item.tipo === 'integer' && resposta === '') {
                        resposta = 0;
                    }
                    const field: ICampoEnviarInscricao = {
                        id: item.id,
                        tipo: item.tipo as IFormulariosCampo['tipo'],
                        resposta,
                    };
                    if (item.obrigatorio) {
                        validateField(field, item.nome);
                    }
                    fields.push(field);
                }
            }

            const data: IEnviarInscricao = {
                formId: idRef.current,
                campos: fields,
            };
            await InscricoesSvc.salvarInscricao(data);

            navigate(`/inscricaoConcluida/${idRef.current}`, {
                replace: true,
                state: null,
            });

            setSaving(false);
        } catch (error) {
            setSaving(false);
            if (!!error?.response) {
                ShowToastError(error?.response?.data?.error?.message);
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
        }
    }

    const obterFormulario = useCallback(async () => {
        try {
            setLoading(true);
            const response = await FormularioSvc.obterFormularioNoAuth(idRef.current);

            if (!response.ativo) {
                return setMessage('Infelizmente este formulário não está mais disponível');
            }

            if (response.dataLimite.getTime() < response.dataAtual.getTime()) {
                return setMessage('Infelizmente este formulário não está mais dentro do período disponível para inscrição');
            }

            setForm(response);
            setCampos(response.campos.filter((val) => val.ativo));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!!error?.response) {
                if (error?.response?.data?.error?.status === 'NOT_FOUND') {
                    return setMessage('O formulário para inscrição não foi encontrado');
                } else {
                    ShowToastError(error?.response?.data?.error?.message);
                }
            } else {
                ShowToastError(error?.message ?? 'Sem conexão com internet');
            }
            setMessage('Ocorreu um erro ao carregar o formulário!');
        }
    }, []);

    useEffect(() => {
        obterFormulario();
    }, [obterFormulario]);

    return (
        <div
            className="box-page"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `linear-gradient(black, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9), black), url('${process.env.PUBLIC_URL}/images/calvary.jpg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '100vh',
            }}
        >
            <img src={calvaryLogo} height="80px" width="80px" style={{ marginRight: 10 }} alt="logo" />
            <h4 className="text-white">Calvary Campo</h4>
            {!!message ? (
                <InfoProblema text={message} />
            ) : (
                <div
                    className="box-page-content"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {loading ? (
                        <div className="flex-center">
                            <div className="spinner-border text-light" role="status" style={{ marginRight: 10 }} />
                        </div>
                    ) : (
                        <div
                            className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3"
                            style={{ backgroundColor: '#141414', padding: '30px', borderRadius: '15px' }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: '30px',
                                }}
                            >
                                <h2 className="text-page-title text-white" dangerouslySetInnerHTML={{ __html: form?.titulo ?? '' }}></h2>
                                <h5
                                    className="text-page-title text-white"
                                    style={{
                                        paddingTop: '30px',
                                        textAlign: 'center',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: form?.descricao ?? '' }}
                                ></h5>
                            </div>
                            <Form onSubmit={salvarInscricao} style={{ width: '100%' }}>
                                {campos.map((item) => (
                                    <Form.Group key={item.id} className="mb-3" controlId={item.id}>
                                        {item.tipo !== 'boolean' && item.tipo !== 'topic' && (
                                            <Form.Label>
                                                {item.obrigatorio ? <span className="text-danger">*</span> : ''}
                                                {item.nome}
                                            </Form.Label>
                                        )}
                                        {item.tipo === 'topic' && <h5 className="text-white mt-4 mb-4">{item.nome}</h5>}
                                        <InputForType form={item} />
                                    </Form.Group>
                                ))}

                                <Button variant="primary" type="submit">
                                    {saving && (
                                        <div
                                            className="spinner-border text-light spinner-border-sm"
                                            role="status"
                                            style={{ marginRight: 10 }}
                                        />
                                    )}
                                    Salvar
                                </Button>
                            </Form>
                        </div>
                    )}
                </div>
            )}
            <br />
            <br />
            <br />
        </div>
    );
}

function validateField(field: ICampoEnviarInscricao, label: string) {
    if (field.tipo === 'integer') {
        if (!(field.resposta > 0)) {
            throw new Error(`Preencha o campo: ${label}`);
        }
    } else if (field.tipo === 'fone') {
        if (field.resposta.length === 0) {
            throw new Error(`Preencha o campo: ${label}`);
        }
        if (field.resposta.length !== 16) {
            throw new Error(`Formato inválido do campo: ${label}`);
        }
    } else {
        if (field.resposta === '') {
            throw new Error(`Preencha o campo: ${label}`);
        }
    }
}

interface IInputForType {
    form: IFormNovoCampo;
}

function InputForType({ form }: IInputForType) {
    if (form.tipo === 'string') {
        return <Form.Control type="text" placeholder="Digite aqui..." required={form.obrigatorio} maxLength={100} />;
    } else if (form.tipo === 'email') {
        return <Form.Control type="email" placeholder="Digite aqui..." required={form.obrigatorio} maxLength={100} />;
    } else if (form.tipo === 'topic') {
        return <span />;
    } else if (form.tipo === 'integer') {
        return <Form.Control type="number" placeholder="Digite aqui..." required={form.obrigatorio} min={0} max={10000} />;
    } else if (form.tipo === 'fone') {
        return <Form.Control type="text" placeholder="Digite aqui..." as={IMaskInput} mask="(00) 0 0000-0000" />;
    } else if (form.tipo === 'boolean') {
        return <Form.Check type="checkbox" label={form.nome} />;
    } else if (form.tipo === 'date') {
        return <Form.Control type="date" required={form.obrigatorio} placeholder="Digite aqui..." />;
    } else if (form.tipo === 'datetime-local') {
        return <Form.Control type="datetime-local" required={form.obrigatorio} placeholder="Digite aqui..." />;
    } else {
        return <Form.Control type="text" required={form.obrigatorio} placeholder="Digite aqui..." />;
    }
}

export default FazerInscricao;
