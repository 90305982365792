import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import Toast from './components/toast/Toast';
import Router from './pages/Router';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <>
        <BrowserRouter>
            <Router />
        </BrowserRouter>
        <Toast />
    </>
);
