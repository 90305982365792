import Lottie from 'react-lottie';
import SadWalking from '../../assets/lottie/sad-guy-is-walking.json';

function InfoProblema({ text }: { text: string }) {
    return (
        <div className="box-empty-list">
            <div className="box-sad-walking">
                <Lottie
                    height={150}
                    width={150}
                    options={{
                        animationData: SadWalking,
                        loop: true,
                    }}
                />
            </div>
            <span className="text-empty-list mt-3">{text}</span>
        </div>
    );
}

export default InfoProblema;
