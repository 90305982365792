import { useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

import calvaryLogo from '../../assets/images/logo_calvary.png';
import Session from '../../utils/Session';

function Header() {
    let navigate = useNavigate();
    const token = useRef(Session.getInfoToken());

    function sair() {
        Session.clear();
        navigate('/login', {
            replace: true,
        });
    }

    function redirect(route: string) {
        navigate(route);
    }

    return (
        <Navbar
            className="no-print"
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            style={{ position: 'fixed', width: '100%', paddingRight: '15px', paddingLeft: '15px' }}
        >
            <Navbar.Brand href="/">
                <img src={calvaryLogo} height="30px" width="30px" style={{ marginRight: 10 }} alt="logo" />
                Painel Administrativo
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    {/*<Nav.Link href="#features">Features</Nav.Link>*/}
                    <NavDropdown title="Eventos" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={() => redirect('/cadastroFormulario')}>Cadastro Formulário</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Link eventKey={2} href="#memes">
                        {token.current?.email ?? ''}
                    </Nav.Link>
                    <button type="button" className="btn btn-link btn-sm" onClick={sair}>
                        Sair
                    </button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;
